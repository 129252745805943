<template>
	<div>
		<CRow v-if="localIcon">
			<!-- desktop icon -->
			<CCol md="12">
				<div class="d-flex justify-content-start align-items-center">
					<BannerInputMedia
						ref="iconDesktopInputMedia"
						:file="localIcon.desktopFile"
						title="Desktop"
						class="banner-item"
						data-test-id="category-desktop-icon-item"
						size="sm"
						:is-show-delete-button="false"
						:is-show-link-input="false"
						@onInputImage="handleDesktopInputImage"
					/>

					<ul class="image-helper pl-4">
						<li>- Recommended resolution W320 x H320px</li>
						<li>- Maximum upload size per file is 10MB</li>
						<li>- Supported PNG, JPG, JPEG, GIF</li>
					</ul>
				</div>
			</CCol>

			<!-- mobile banner -->
			<CCol md="12">
				<div class="d-flex justify-content-start align-items-center">
					<BannerInputMedia
						ref="iconMobileInputMedia"
						:file="localIcon.mobileFile"
						title="Mobile"
						class="banner-item"
						data-test-id="category-mobile-icon-item"
						size="sm"
						:is-show-delete-button="false"
						:is-show-link-input="false"
						@onInputImage="handleMobileInputImage"
					/>

					<ul class="image-helper pl-4">
						<li>- Recommended resolution W64 x H64px</li>
						<li>- Maximum upload size per file is 2MB</li>
						<li>- Supported PNG, JPG, JPEG, GIF</li>
					</ul>
				</div>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import BannerInputMedia from '@/components/BannerInputMedia.vue';

export default {
	name: 'CategoryIcon',

	components: {
		BannerInputMedia,
	},

	props: {
		value: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			localIcon: null,
		};
	},

	created() {
		this.localIcon = this.value ?? null;
	},

	methods: {
		handleDesktopInputImage(desktopFile) {
			this.localIcon = {
				...this.localIcon,
				desktopFile,
			};

			this.emitInputValue();
		},

		handleMobileInputImage(mobileFile) {
			this.localIcon = {
				...this.localIcon,
				mobileFile,
			};

			this.emitInputValue();
		},

		emitInputValue() {
			this.$emit('input', this.localIcon);
		},
	},
};
</script>

<style lang="scss" scoped>
.image-helper {
	list-style: none;
	margin: 0;

	li {
		@include typo-body-2;

		color: $color-black-45;
	}
}
</style>
