<template>
	<div class="main-wrapper-large">
		<BaseLoading v-if="isLoading" is-full-page />
		<CRow v-else>
			<CategoryEditSidebar :level="level" @onClickNav="scrollToSection" />
			<CCol sm="8">
				<form @submit.prevent="handleSubmit">
					<CRow class="mb-5">
						<!-- Category level -->
						<CCol :ref="CATEGORY_REFS.LEVEL" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.LEVEL] }}
							</h4>
							<p class="label level-label">
								Creating category
							</p>
							<BaseButtonGroup
								v-model="level"
								:list="CATEGORY_LEVEL_OPTIONS"
								disabled
							/>
							<CRow v-if="isParentSelectable" class="row-parent-category">
								<CCol md="6">
									<BaseDropDown
										v-model="$v.categoryParentLevelOne.$model"
										:options="categoryParentLevelOneOptions"
										:searchable="false"
										:is-valid="isValidCategoryParentLevelOne"
										:invalid-feedback="validatecategoryParentLevelOneMessage"
										label-drop-down="Under parent category (level 1)*"
										label="name"
										track-by="value"
										placeholder="Selection"
										class="select-custom"
										test-id="category-level-one-dropdown"
										@input="handleSelectedCategoryParentLevelOne"
									/>
								</CCol>
								<CCol v-if="isLevelThree" md="6">
									<BaseDropDown
										v-model="$v.categoryParentLevelTwo.$model"
										:options="categoryParentLevelTwoOptions"
										:searchable="false"
										:is-valid="isValidCategoryParentLevelTwo"
										:invalid-feedback="validatecategoryParentLevelTwoMessage"
										:disabled="!categoryParentLevelOne"
										label-drop-down="Under parent category (level 2)*"
										label="name"
										track-by="value"
										placeholder="Selection"
										class="select-custom"
										test-id="category-level-two-dropdown"
										@input="clearError"
									/>
								</CCol>
							</CRow>
							<hr>
						</CCol>
						<!-- end Category level -->

						<!-- General info -->
						<CCol :ref="CATEGORY_REFS.GENERAL_INFO" lg="12">
							<div
								class="mb-4 d-flex justify-content-between align-items-center"
							>
								<h4 class="mb-0">
									{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.GENERAL_INFO] }}
								</h4>
								<p
									v-if="categoryInfo.id"
									class="typo-body-2 mb-0 category-id"
								>
									{{ $t("page.categories.edit.categoryId") }}:
									{{ categoryInfo.id }}
								</p>
							</div>
							<CRow>
								<CCol md="6">
									<CInput
										v-model.trim="$v.name.$model"
										data-id="edit-category-name-en"
										:is-valid="isValidName"
										:invalid-feedback="validateNameMessage"
										label="Category name (EN)*"
										@input="clearError"
										@blur="handleAutoFillMetaTitleAndSlug"
									/>
								</CCol>
								<CCol md="6">
									<CInput
										v-model.trim="$v.nameTH.$model"
										data-id="edit-category-name-th"
										:is-valid="isValidNameTH"
										:invalid-feedback="validateNameTHMessage"
										label="Category name (TH)*"
										@input="clearError"
										@blur="handleAutoFillMetaTitleTH"
									/>
								</CCol>
								<CCol md="12">
									<CInput
										v-model.trim="$v.slug.$model"
										:is-valid="isValidSlug"
										:invalid-feedback="validateSlugMessage"
										:description="slugHelperText"
										label="Slug"
										@input="clearError"
									/>
								</CCol>
								<CCol md="12">
									<CInput
										v-model.trim="$v.customUrl.$model"
										:is-valid="!$v.customUrl.$error && null"
										:invalid-feedback="$t('global.error.url')"
										:description="'Allow only url. example: https://www.studio7thailand.com'"
										label="Custom URL"
										@input="clearError"
									/>
								</CCol>
								<CCol md="6">
									<CInput
										v-model.trim="commission"
										data-id="edit-commission"
										label="Commission (%)"
										@input="clearError"
									/>
								</CCol>
								<CCol
									xl="12"
									class="d-flex align-items-center margin-bottom-32"
								>
									<label class="toggle-label" for="category-status">
										Category status
									</label>
									<CSwitch
										id="category-status"
										data-id="category-status"
										:checked="status"
										variant="3d"
										size="sm"
										class="switch-custom toggle-status"
										color="success"
										@update:checked="handleUpdateStatus"
									/>
									<span>{{ statusText }}</span>
								</CCol>
								<template v-if="!isLevelThree">
									<CCol
										xl="12"
										class="d-flex align-items-center margin-bottom-32"
									>
										<label class="toggle-label" for="category-is-new">
											New category visibility <base-tooltip :message="$t('page.categories.tooltip.isNew')" />
										</label>
										<CSwitch
											id="category-is-new"
											data-id="category-is-new"
											:checked="isNew"
											variant="3d"
											size="sm"
											class="switch-custom toggle-status"
											color="success"
											@update:checked="handleUpdateIsNew"
										/>
										<span>{{ isNewText }}</span>
									</CCol>
								</template>
								<template v-if="isLevelOne">
									<CCol
										xl="12"
										class="d-flex align-items-center margin-bottom-32"
									>
										<label class="toggle-label" for="category-is-visibility-all">
											View all visibility <base-tooltip :message="$t('page.categories.tooltip.visibleViewAll')" />
										</label>
										<CSwitch
											id="category-is-visibility-all"
											data-id="category-is-visibility-all"
											:checked="isVisibleViewAll"
											variant="3d"
											size="sm"
											class="switch-custom toggle-status"
											color="success"
											@update:checked="handleUpdateIsVisibleViewAll"
										/>
										<span>{{ visibleViewAllText }}</span>
									</CCol>
								</template>
								<CCol xl="12" class="d-flex align-items-center margin-bottom-32">
									<label class="toggle-label" for="category-visibility">
										Storefront visibility
									</label>
									<CSwitch
										id="category-visibility"
										:checked="visibility"
										variant="3d"
										size="sm"
										class="switch-custom toggle-visibility"
										color="success"
										@update:checked="handleUpdateVisibility"
									/>
									<span>{{ visibilityText }}</span>
								</CCol>
								<CCol xl="12" class="d-flex align-items-center margin-bottom-32">
									<label class="toggle-label" for="navbar-visibility">
										Navbar visibility <base-tooltip :message="$t('page.categories.tooltip.navbarVisibility')" />
									</label>
									<CSwitch
										id="navbar-visibility"
										:checked="navbarVisibility"
										variant="3d"
										size="sm"
										class="switch-custom toggle-visibility"
										color="success"
										@update:checked="handleUpdateNavbarVisibility"
									/>
									<span>{{ navbarVisibilityText }}</span>
								</CCol>
								<!-- hide for temporary -->
								<CCol v-if="false" xl="12" class="d-flex align-items-center">
									<label class="toggle-label" for="true-trade-in-visibility">
										TRUE / Trade in visibility <base-tooltip :message="$t('page.categories.tooltip.visibilityTradeIn')" />
									</label>
									<CSwitch
										id="true-trade-in-visibility"
										data-id="true-trade-in-visibility"
										:checked="tradeInTruemoveVisibility"
										variant="3d"
										size="sm"
										class="switch-custom toggle-visibility"
										color="success"
										@update:checked="handleUpdateTradeInTruemoveVisibility"
									/>
									<span>{{ visibilityTradeInText }}</span>
								</CCol>
							</CRow>
							<hr>
						</CCol>
						<!-- end General info -->

						<!-- Icon -->
						<CCol v-if="!isLevelThree" :ref="CATEGORY_REFS.ICON" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.ICON] }}
							</h4>
							<CRow>
								<CCol md="12" class="mb-3">
									{{ $t("page.categories.edit.categoryIcon") }}
								</CCol>
								<CCol>
									<CategoryIcon
										ref="categoryIcon"
										v-model="icon"
									/>
								</CCol>
							</CRow>
							<hr>
						</CCol>
						<!-- end Icon -->

						<!-- Compare -->
						<CCol :ref="CATEGORY_REFS.PRODUCT_COMPARE" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.PRODUCT_COMPARE] }}
							</h4>
							<CRow>
								<CCol md="12" class="mb-3">
									{{ $t("page.categories.edit.productCompare") }}
								</CCol>
								<CCol md="6">
									<CInput
										v-model.trim="compareEN"
										data-id="compare-text-en"
										label="Compare text (EN)"
										@input="clearError"
									/>
								</CCol>
								<CCol md="6">
									<CInput
										v-model.trim="compareTH"
										data-id="compare-text-th"
										label="Compare text (TH)"
										@input="clearError"
									/>
								</CCol>
								<CCol md="6">
									<CInput
										v-model.trim="compareDescriptionEN"
										data-id="compare-text-en"
										label="Compare description (EN)"
										@input="clearError"
									/>
								</CCol>
								<CCol md="6">
									<CInput
										v-model.trim="compareDescriptionTH"
										data-id="compare-text-th"
										label="Compare description (TH)"
										@input="clearError"
									/>
								</CCol>
							</CRow>
							<CRow>
								<CCol md="7">
									<h6>
										Product Compare additional content
									</h6>
									<div class="description typo-helper-text color-black-45">
										Product compare will be displayed at the bottom of the product detail page
									</div>
								</CCol>
								<CCol md="5" class="text-right">
									<CButton
										color="secondary"
										class="btn btn-secondary text-nowrap"
										@click.prevent="$refs['modal-category-compare'].open()"
									>
										Add Compare
									</CButton>
								</CCol>
								<CCol md="12">
									<div class="compare-list mt-3" :class="emptyClasses">
										<draggable
											v-if="htmlContents.length"
											:value="htmlContents"
											tag="ul"
											ghost-class="is-placeholder-item"
											class="compare-list-dragable"
											@input="handleCompareOrder"
										>
											<CompareItem
												v-for="(data, index) in htmlContents"
												:key="index"
												:data="data"
												class="compare-list-item"
												@onMoveToTop="moveCompareToTop(index)"
												@onEdit="handleOnEditCompare(data, index)"
												@onDelete="handleOnDeleteCompare(index)"
											/>
										</draggable>
										<template v-else>
											<span class="subtitle color-black-45">No compare in the list</span>
										</template>
									</div>
								</CCol>
							</CRow>
							<hr>
						</CCol>
						<!-- end Compare -->

						<!-- Slide category -->
						<CCol v-if="isLevelOne" :ref="CATEGORY_REFS.SLIDE_BANNER">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.SLIDE_BANNER] }}
							</h4>
							<CRow>
								<CCol md="12" class="mb-3">
									{{ $t("page.categories.edit.categorySlideBanner") }}
								</CCol>
								<CCol
									xl="12"
									class="d-flex align-items-center mb-3"
								>
									<label class="toggle-label" for="banner-visibility">
										Storefront visibility
									</label>
									<CSwitch
										id="banner-visibility"
										data-id="banner-visibility"
										:checked="isBannerVisibility"
										variant="3d"
										size="sm"
										class="switch-custom toggle-visibility"
										color="success"
										@update:checked="handleUpdateBannerVisibility"
									/>
									<span>{{ bannerVisibilityText }}</span>
								</CCol>
								<CCol
									xl="12"
									class="d-flex align-items-center mb-3"
								>
									<label class="toggle-label" for="lowest-price-visibility">
										Price visibility
									</label>
									<CSwitch
										id="lowest-price-visibility"
										data-id="lowest-price-visibility"
										:checked="isLowestPriceVisibility"
										variant="3d"
										size="sm"
										class="switch-custom toggle-visibility"
										color="success"
										:disabled="!isBannerVisibility"
										@update:checked="handleUpdateLowestPriceVisibility"
									/>
									<span>{{ lowestPriceVisibilityText }}</span>
								</CCol>
								<CCol xl="12">
									<CRow>
										<CCol md="7">
											<h6 data-test-id="title">
												Category Price
											</h6>
											<div class="description typo-helper-text color-black-45">
												Use Category Level 2 to manage the lowest price of the Category Slide Banner.
											</div>
										</CCol>
										<CCol md="5" class="text-right">
											<CButton
												class="transparent"
												color="secondary"
												@click="$refs['modal-category'].open()"
											>
												<CIcon class="icon-left" name="cil-plus" />
												edit category
											</CButton>
										</CCol>
										<CCol md="12" class="mt-3">
											<ul v-if="lowestPriceCategoryIdList.length" class="category-slide-list">
												<li v-for="(categoryId, index) in lowestPriceCategoryIdList" :key="index" class="list-item typo-body-2">
													{{ categoryId.name }}
												</li>
											</ul>
										</CCol>
									</CRow>
								</CCol>
							</CRow>
							<hr>
						</CCol>
						<!-- end Slide category -->

						<!-- Product Ranking -->
						<CCol :ref="CATEGORY_REFS.PRODUCT_RANK" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.PRODUCT_RANK] }}
							</h4>
							<CRow>
								<CCol>
									<CButton
										class="transparent mr-3"
										color="secondary"
										data-test-id="export-product-ranking"
										@click="exportProductRanking"
									>
										Export .xlsx
									</CButton>
									<CButton
										class="transparent"
										color="secondary"
										data-test-id="import-product-ranking"
										@click="importProductRanking"
									>
										Import .xlsx
									</CButton>
								</CCol>
							</CRow>
							<hr>
						</CCol>
						<!-- end Product Ranking -->

						<!-- Filters -->
						<CCol :ref="CATEGORY_REFS.FILTER" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.FILTER] }}
							</h4>
							<CRow>
								<CCol md="12" class="mb-3">
									{{ $t("page.categories.edit.filterDetail") }}
								</CCol>
								<CCol xl="12">
									<CButton
										:to="{
											name: 'CategoryAttributeEdit',
											params: {
												id: this.$route.params.id
											}
										}"
										color="secondary"
										class="transparent"
										data-id="manage-filter"
									>
										Manage filters
									</CButton>
								</CCol>
							</CRow>
							<hr>
						</CCol>
						<!-- end Filters -->

						<!-- Product families -->
						<CCol v-if="!isLevelThree" :ref="CATEGORY_REFS.PRODUCT_FAMILY" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.PRODUCT_FAMILY] }}
							</h4>
							<CRow class="product-famillies">
								<CCol xl="12" class="mb-3">
									{{ $t("page.categories.edit.addProductFamiles") }}
								</CCol>
								<CCol xl="12" md="6">
									<CButton
										color="secondary"
										class="transparent"
										data-test-id="product-families-group-add"
										@click="$refs['modal-product-families-group'].open()"
									>
										Manage product families
									</CButton>
								</CCol>
							</CRow>
							<hr>
						</CCol>
						<!-- end Product families -->

						<!-- Badge Labels -->
						<!-- <CCol :ref="CATEGORY_REFS.BADGE_LABEL" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.BADGE_LABEL] }}
							</h4>
							<CRow>
								<CCol xl="12">
									<ProductEditLabelList
										:type="PRODUCT_LABEL_TYPES.badge"
										:list="badgeLabels"
										:disabled="disableAddBadgeLabelButton"
										title="Badge label"
										description="Badge label will display on top category title on Product Families/LOB Pages"
										@onAdd="openProductBadgeModal(PRODUCT_LABEL_TYPES.badge)"
										@onDelete="handleDeleteBadgeLabel"
									/>
								</CCol>
							</CRow>
							<hr class="mt-4 mb-5">
						</CCol> -->
						<!-- end Badge Labels -->

						<!-- LOB/PLP -->
						<CCol lg="12">
							<h4 :ref="CATEGORY_REFS.CATEGORY_REDIRECTION" class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.CATEGORY_REDIRECTION] }}
							</h4>
							<CRow class="mb-3">
								<CCol>
									This section will only affect categories on the navbar and category items on the Family Stripe
								</CCol>
							</CRow>
							<CRow>
								<CCol
									xl="12"
									class="d-flex align-items-center"
								>
									<BaseDropDown
										v-model="categoryDisplayType"
										class="w-100"
										:options="CATEGORY_DISPLAY_TYPE_OPTIONS"
										label="label"
										label-drop-down="Category Display"
										placeholder="Select category display type"
									/>
								</CCol>
								<CCol v-if="categoryDisplayType.value === CATEGORY_DISPLAY_TYPE.SEARCH_LIST" md="12">
									<CInput
										v-model.trim="searchKeyword"
										description="search keyword for type search"
										label="Search Keyword"
									/>
								</CCol>
							</CRow>
							<hr class="mt-4 mb-5">
						</CCol>
						<CCol :ref="CATEGORY_REFS.CATEGORY_CONTENT" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.CATEGORY_CONTENT] }}
							</h4>
							<CategoryContentAttaches
								:items="localCategoryContentItems"
								title="Category content"
								description="These products/categories are will show when set and enable LOB/PLP in categories."
								is-draggable
								:display-type="categoryDisplayType.value"
								@update:items="(value) => localCategoryContentItems=value"
								@onDragged="(newList) => localCategoryContentItems=newList"
							/>
							<hr class="mt-4 mb-5">
						</CCol>
						<!-- end LOB/PLP -->

						<!-- category banner -->
						<CCol :ref="CATEGORY_REFS.CONTENT" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.CONTENT] }}
							</h4>
							<CRow>
								<CCol md="12">
									<CategoryContentBannerAttaches
										:banners="banners"
										@update:banners="banners = $event"
									/>
								</CCol>
							</CRow>
							<CRow class="mt-2">
								<CCol lg="12">
									<label>Category description (top)</label>
									<div class="d-flex mb-2">
										<label class="toggle-label -is-expand-content" for="category-auto-expand">
											Auto expand content
										</label>
										<CSwitch
											id="category-auto-expand"
											:checked="isExpand"
											variant="3d"
											size="sm"
											class="switch-custom toggle-visibility"
											color="success"
											@update:checked="handleUpdateExpand"
										/>
										<span>{{ expandText }}</span>
									</div>
									<BaseRichTextEditor
										v-model="descriptionTop"
										name="descriptionTop"
									/>
								</CCol>
							</CRow>
							<CRow class="mt-2">
								<CCol lg="12">
									<label>Category description (bottom)</label>
									<BaseRichTextEditor
										v-model="descriptionBottom"
										name="descriptionBottom"
									/>
								</CCol>
							</CRow>
							<hr class="mt-4 mb-5">
						</CCol>
						<!-- end Content -->

						<!-- SEO -->
						<CCol :ref="CATEGORY_REFS.SEO" lg="12">
							<h4 class="mb-4">
								{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.SEO] }}
							</h4>
							<CRow>
								<CCol md="6">
									<CTextarea
										v-model.trim="metaTitle"
										label="Meta title (EN)"
										rows="2"
										@input="clearError"
									/>
								</CCol>
								<CCol md="6">
									<CTextarea
										v-model.trim="metaTitleTH"
										label="Meta title (TH)"
										rows="2"
										@input="clearError"
									/>
								</CCol>
								<CCol md="6">
									<CTextarea
										v-model.trim="metaKeyword"
										label="Meta keywords (EN)"
										rows="3"
										@input="clearError"
									/>
								</CCol>
								<CCol md="6">
									<CTextarea
										v-model.trim="metaKeywordTH"
										label="Meta keywords (TH)"
										rows="3"
										@input="clearError"
									/>
								</CCol>
								<CCol md="6">
									<CTextarea
										v-model.trim="metaDescription"
										label="Meta description (EN)"
										rows="6"
										class="mb-0"
										@input="clearError"
									/>
								</CCol>
								<CCol md="6">
									<CTextarea
										v-model.trim="metaDescriptionTH"
										label="Meta description (TH)"
										rows="6"
										class="mb-0"
										@input="clearError"
									/>
								</CCol>
							</CRow>
							<hr>
						</CCol>
						<!-- end SEO -->
					</CRow>

					<BaseActionPanelStickyFooter
						:disabled-confirm="isUpdating"
						content-class="main-wrapper"
						is-edit
						remove-text="Remove category"
						@onConfirm="handleSubmit"
						@onCancel="$router.push({ name: 'CategoryLists' })"
						@onRemove="handleOpenModalConfirmDelete"
					/>

					<!-- Remove modal confirm -->
					<CModal
						:show.sync="showModalConfirmDelete"
						:title="$t('page.categories.delete.modalConfirm.title')"
						centered
					>
						<template #default>
							<p>
								{{ $t("page.categories.delete.modalConfirm.description") }}
							</p>
							<form @submit.prevent="handleRemove">
								<CInput
									v-model.trim="inputConfirmText"
									:placeholder="$t('page.categories.delete.modalConfirm.inputPlaceholder')"
									class="mb-0"
								/>
							</form>
						</template>
						<template #footer>
							<CButton
								color="tertiary"
								class="mr-3"
								@click="showModalConfirmDelete = false"
							>
								Cancel
							</CButton>
							<CButton
								:disabled="!isDeletable || isDeleting"
								data-id="remove"
								color="danger"
								@click="handleRemove"
							>
								Remove
							</CButton>
						</template>
					</CModal>

					<!-- modal product compare -->
					<ModalCategoryCompare
						ref="modal-category-compare"
						@onConfirm="handleCategoryCompareChange"
					/>
					<!-- modal product families -->
					<ModalProductFamiliesGroup
						ref="modal-product-families-group"
						:list="productFamiliesGroup"
						@onConfirm="handleProductFamiliesGroupListChange"
					/>
					<!-- modal product label -->
					<!-- hide for S7APP-1206 -->
					<!-- <ModalProductLabel
						ref="modal-badge-label"
						@onSubmit="handleSubmitBadgeLabel"
					/> -->
					<!-- modal category -->
					<ModalCategory
						ref="modal-category"
						data-test-id="modal-category"
						is-diabled-lv1
						:selected-ids="lowestPriceCategoryIds"
						:enable-select-all="false"
						:filtered-categories="[Number($route.params.id)]"
						@onSubmit="handleLowestPriceCategoryChange"
					/>
				</form>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { required, helpers, url } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';

import CompareItem from '@/components/CompareItem.vue';
import CategoryIcon from '@/components/CategoryIcon.vue';
// import CategoryBanners from '@/components/CategoryBanners.vue';
import ModalCategoryCompare from '@/components/ModalCategoryCompare.vue';
import ModalProductFamiliesGroup from '@/components/ModalProductFamiliesGroup.vue';
// import ModalProductLabel from '@/components/ModalProductLabel.vue';
import ModalCategory from '@/components/ModalCategory.vue';
// import ProductEditLabelList from '@/components/ProductEditLabelList.vue';
import CategoryContentAttaches from '@/components/CategoryContentAttaches.vue';
import CategoryEditSidebar from '@/components/CategoryEditSidebar.vue';
import CategoryContentBannerAttaches from '@/components/CategoryContentBannerAttaches.vue';

import { pathOr, scrollToTop, convertDateTimeToUTC } from '../assets/js/helpers';
import { transformedCategoryDropdownOption } from '../assets/js/transform/category';

import slug from '../regex/slug';

import { exportAPI } from '../services/api/export.api';
import { importProductRankingAPI } from '../services/api/categories.api';

import {
	STATUS as mappingStatus,
	VISIBILITY as mappingVisibility,
	EXPAND as mappingExpand,
} from '../mapping/categories';
import {
	CATEGORY_LEVEL,
	CATEGORY_LEVEL_OPTIONS,
	CATEGORY_DISPLAY_TYPE_OPTIONS,
	CATEGORY_DISPLAY_TYPE,
	CONFIRM_DELETE_TEXT,
	CATEGORY_REFS,
	CATEGORY_REFS_TITLE,
} from '../enums/categories';
import errorMessage from '../enums/errorMessage';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

const slugify = require('slugify');

export default {
	name: 'CategoryEditPage',
	components: {
		draggable,
		CompareItem,
		CategoryIcon,
		// CategoryBanners,
		ModalCategoryCompare,
		ModalProductFamiliesGroup,
		CategoryContentAttaches,
		// ModalProductLabel,
		// ProductEditLabelList,
		CategoryEditSidebar,
		ModalCategory,
		CategoryContentBannerAttaches,
	},
	validations() {
		// NOTE: Use code like this for use variable
		return {
			categoryParentLevelOne: {
				required: (value) => {
					if (this.isParentSelectable) {
						return required(value);
					}
					return true; // Pass when level 1
				},
			},
			categoryParentLevelTwo: {
				required: (value) => {
					if (this.isLevelThree) {
						return required(value);
					}
					return true; // Pass when level 1 & 2
				},
			},
			name: {
				required,
			},
			nameTH: {
				required,
			},
			slug: {
				required,
				format: helpers.regex('slug', slug),
				duplicate: () => !this.edit.fieldErrors.slug,
			},
			customUrl: {
				url,
			},
			link: {
				url,
			},
		};
	},
	data() {
		return {
			CATEGORY_REFS,
			CATEGORY_REFS_TITLE,

			// Level
			CATEGORY_LEVEL,
			CATEGORY_LEVEL_OPTIONS,
			level: null,
			categoryParentLevelOne: null,
			categoryParentLevelTwo: null,

			// General
			name: null,
			nameTH: null,
			slug: null,
			customUrl: null,
			commission: null,

			// Compare
			htmlContents: [],
			link: null,
			compareEN: null,
			compareTH: null,
			compareDescriptionEN: null,
			compareDescriptionTH: null,

			// Status
			isLoading: true,
			isNew: false,
			isVisibleViewAll: false,
			status: false,
			visibility: false,
			navbarVisibility: false,
			tradeInTruemoveVisibility: false,
			isExpand: false,

			// More info
			descriptionTop: '',
			descriptionBottom: '',

			// SEO
			metaTitle: null,
			metaTitleTH: null,
			metaKeyword: null,
			metaKeywordTH: null,
			metaDescription: null,
			metaDescriptionTH: null,

			// Media
			banners: [],
			icon: null,
			indexBanner: null,

			// Modal confirm delete
			showModalConfirmDelete: false,
			inputConfirmText: null,

			// Category banner
			isBannerVisibility: false,
			isLowestPriceVisibility: false,
			lowestPriceCategoryIds: [],

			// Product Families
			productFamiliesGroup: [],

			// Badge Label
			badgeLabels: [],
			PRODUCT_LABEL_TYPES,

			// LOB/PLP settings
			categoryDisplayType: false,
			searchKeyword: null,

			// category Content (LOB/PLP)
			CATEGORY_DISPLAY_TYPE_OPTIONS,
			CATEGORY_DISPLAY_TYPE,
			localCategoryContentItems: [],

			isDrag: false,
		};
	},
	computed: {
		...mapState('categories', {
			edit: 'edit',
			delete: 'delete',
		}),
		...mapGetters({
			categoryLists: 'categories/categoryLists',
			categoryInfo: 'categories/categoryInfo',
		}),
		isUpdating() {
			return this.edit.isUpdating;
		},
		isDeleting() {
			return this.delete.isLoading;
		},

		// Categories dropdown options
		categoryParentLevelOneOptions() {
			return this.categoryLists.map(transformedCategoryDropdownOption);
		},
		categoryParentLevelTwoOptions() {
			if (this.categoryParentLevelOne) {
				const id = this.categoryParentLevelOne.value;
				const levelOneItem = this.categoryLists.find((category) => category.id === id) || {};
				const list = levelOneItem.children || [];

				return list.map(transformedCategoryDropdownOption);
			}
			return [];
		},

		isParentSelectable() {
			return this.level !== CATEGORY_LEVEL.ONE;
		},
		isLevelOne() {
			return this.level === CATEGORY_LEVEL.ONE;
		},
		isLevelThree() {
			return this.level === CATEGORY_LEVEL.THREE;
		},
		isValidCategoryParentLevelOne() {
			const isError = pathOr(false, [
				'$v',
				'categoryParentLevelOne',
				'$error',
			])(this);
			return isError ? false : null;
		},
		validatecategoryParentLevelOneMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'categoryParentLevelOne',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidCategoryParentLevelTwo() {
			const isError = pathOr(false, [
				'$v',
				'categoryParentLevelTwo',
				'$error',
			])(this);
			return isError ? false : null;
		},
		validatecategoryParentLevelTwoMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'categoryParentLevelTwo',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		// For submit
		categoryParentId() {
			if (this.isParentSelectable && this.isLevelThree) {
				return pathOr(null, ['categoryParentLevelTwo', 'value'])(this);
			} else if (this.isParentSelectable) {
				return pathOr(null, ['categoryParentLevelOne', 'value'])(this);
			}
			return null;
		},
		isValidName() {
			const isError = pathOr(false, ['$v', 'name', '$error'])(this);
			return isError ? false : null;
		},
		validateNameMessage() {
			const validateRequired = pathOr(false, ['$v', 'name', 'required'])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidNameTH() {
			const isError = pathOr(false, ['$v', 'nameTH', '$error'])(this);
			return isError ? false : null;
		},
		validateNameTHMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'nameTH',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidSlug() {
			const isError = pathOr(false, ['$v', 'slug', '$error'])(this);
			return isError ? false : null;
		},
		validateSlugMessage() {
			const validateRequired = pathOr(false, ['$v', 'slug', 'required'])(this);
			const validateFormat = pathOr(false, ['$v', 'slug', 'format'])(this);
			const validateDuplicate = pathOr(false, [
				'$v',
				'slug',
				'duplicate',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			} else if (!validateFormat) {
				return this.$t('page.categories.create.slugFormat');
			} else if (!validateDuplicate) {
				return this.$t('page.categories.create.duplicateSlug');
			}
			return null;
		},
		slugHelperText() {
			return this.isValidSlug !== false
				? this.$t('page.categories.create.slugFormat')
				: '';
		},
		isNewText() {
			const text = mappingVisibility[this.isNew] || '';
			return this.$t(text);
		},
		visibleViewAllText() {
			const text = mappingVisibility[this.isVisibleViewAll] || '';
			return this.$t(text);
		},
		statusText() {
			const text = mappingStatus[this.status] || '';
			return this.$t(text);
		},
		visibilityText() {
			const text = mappingVisibility[this.visibility] || '';
			return this.$t(text);
		},
		navbarVisibilityText() {
			const text = mappingVisibility[this.navbarVisibility] || '';
			return this.$t(text);
		},
		visibilityTradeInText() {
			const text = mappingVisibility[this.tradeInTruemoveVisibility] || '';
			return this.$t(text);
		},
		isDeletable() {
			return this.inputConfirmText === CONFIRM_DELETE_TEXT;
		},
		expandText() {
			const text = mappingExpand[this.isExpand] || '';
			return this.$t(text);
		},
		bannerVisibilityText() {
			const text = mappingVisibility[this.isBannerVisibility] || '';
			return this.$t(text);
		},
		lowestPriceVisibilityText() {
			const text = mappingVisibility[this.isLowestPriceVisibility] || '';
			return this.$t(text);
		},

		// Compare
		emptyClasses() {
			return !this.htmlContents.length ?
				'd-flex align-items-center justify-content-center'
				: '';
		},

		lowestPriceCategoryIdList() {
			return this.lowestPriceCategoryIds.map((categoryId) => {
				const data = this.edit.data.children.find((item) => item.id === categoryId || item.id === categoryId.id);
				return {
					id: categoryId,
					name: data?.name || null,
				};
			});
		},
	},
	async created() {
		const categoryId = this.$route.params.id;

		await this.getCategories();
		await this.getCategory(categoryId);

		// Set category infomation
		this.level = this.categoryInfo.level;
		this.name = this.categoryInfo.name;
		this.nameTH = this.categoryInfo.nameTH;
		this.slug = this.categoryInfo.slug;
		this.customUrl = this.categoryInfo.customUrl;
		this.commission = this.categoryInfo.commission;
		this.status = this.categoryInfo.status;
		this.visibility = this.categoryInfo.visibility;
		this.navbarVisibility = this.categoryInfo.navbarVisibility;
		this.tradeInTruemoveVisibility = this.categoryInfo.tradeInTruemoveVisibility;
		this.metaTitle = this.categoryInfo.seo.title;
		this.metaTitleTH = this.categoryInfo.seo.titleTH;
		this.metaKeyword = this.categoryInfo.seo.keyword;
		this.metaKeywordTH = this.categoryInfo.seo.keywordTH;
		this.metaDescription = this.categoryInfo.seo.description;
		this.metaDescriptionTH = this.categoryInfo.seo.descriptionTH;
		this.descriptionTop = this.categoryInfo.descriptionTop || '';
		this.descriptionBottom = this.categoryInfo.descriptionBottom || '';
		this.isNew = this.categoryInfo.new;
		this.isVisibleViewAll = this.categoryInfo.isVisibleViewAll;
		this.banners = this.categoryInfo.banners;
		this.icon = this.categoryInfo.icon;
		this.htmlContents = this.categoryInfo.htmlContents ?? [];
		this.link = this.categoryInfo.link;
		this.compareEN = this.categoryInfo.compareEN;
		this.compareTH = this.categoryInfo.compareTH;
		this.compareDescriptionEN = this.categoryInfo.compareDescriptionEN || '';
		this.compareDescriptionTH = this.categoryInfo.compareDescriptionTH || '';
		this.categoryParentLevelOne =
			this.categoryParentLevelOneOptions.find((option) => option.value === this.categoryInfo.parentLevelOneId) || null;
		this.categoryParentLevelTwo =
			this.categoryParentLevelTwoOptions.find((option) => option.value === this.categoryInfo.parentLevelTwoId) || null;
		this.isExpand = this.categoryInfo.isExpand;

		// category slide banner
		this.isBannerVisibility = this.categoryInfo.isBannerVisibility;
		this.isLowestPriceVisibility = this.categoryInfo.isLowestPriceVisibility;
		this.lowestPriceCategoryIds = this.categoryInfo.lowestPriceCategoryIds;

		this.productFamiliesGroup = this.categoryInfo.productFamiliesGroup;
		//* hide for S7APP-1206
		// this.badgeLabels = this.categoryInfo.labels.filter((item) => item.type === PRODUCT_LABEL_TYPES.badge);

		// LOB/PLP
		this.categoryDisplayType = CATEGORY_DISPLAY_TYPE_OPTIONS.find((option) => option.value === this.categoryInfo.categoryDisplayType);
		this.searchKeyword = this.categoryInfo.searchKeyword;
		this.localCategoryContentItems = this.categoryInfo.categoryContents;

		// Set page title
		this.setPageTitle({
			title: this.name,
			isShow: true,
		});

		this.isLoading = false;
	},
	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			showToast: 'toast/showToast',
			getCategories: 'categories/getCategories',
			getCategory: 'categories/getCategory',
			updateCategory: 'categories/updateCategory',
			clearError: 'categories/clearEditError',
			deleteCategory: 'categories/deleteCategory',
		}),

		async scrollToSection(refNames = []) {
			const element = refNames.reduce((result, current) => {
				if (result) {
					return result.$refs[current];
				}
				return this.$refs[current];
			}, null);

			if (element) {
				await this.$nextTick();
				if (element instanceof Element) {
					element.scrollIntoView({ behavior: 'smooth' });
				} else {
					element.$el.scrollIntoView({ behavior: 'smooth' });
				}
			}
		},

		sortHtmlContents() {
			if (this.isDrag) {
				this.htmlContents.forEach((content, index) => {
					content.priority = index;
				});
			}
		},

		async handleSubmit() {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.sortHtmlContents();

				const categoryId = this.$route.params.id;
				const categoryData = {
					parent_id: this.categoryParentId,
					name_en: this.name,
					name_th: this.nameTH,
					slug: this.slug,
					custom_url: this.customUrl ?? null, // if is empty string change it to null
					is_new: this.isNew,
					is_visible_view_all: this.isVisibleViewAll,
					is_active: this.status,
					is_visible: this.visibility,
					is_navbar_visible: this.navbarVisibility,
					is_trade_in_truemove_visible: this.tradeInTruemoveVisibility,
					media: {
						icons: {
							desktop_file_id: this.icon?.desktopFile?.id ?? null,
							mobile_file_id: this.icon?.mobileFile?.id ?? null,
						},
					},
					banners: this.banners?.map((banner, index) => {
						return {
							link: banner.link,
							desktop_file_id: banner.desktopFile?.id,
							desktop_en_file_id: banner.desktopENFile?.id,
							mobile_file_id: banner.mobileFile?.id,
							mobile_en_file_id: banner.mobileENFile?.id,
							is_active: banner.isActive,
							order: index,
							start_at: banner.startDate ? convertDateTimeToUTC(banner.startDate, banner.startTime ?? '00:00') : null,
							end_at: banner.endDate ? convertDateTimeToUTC(banner.endDate, banner.endTime ?? '23:59') : null,
						};
					}),
					banner_link: null,
					description_top: this.descriptionTop,
					description_bottom: this.descriptionBottom,
					seo: {
						title_en: this.metaTitle,
						title_th: this.metaTitleTH,
						description_en: this.metaDescription,
						description_th: this.metaDescriptionTH,
						keyword_en: this.metaKeyword,
						keyword_th: this.metaKeywordTH,
					},
					commission: this.commission,
					compare_link: this.link,
					compare_text_en: this.compareEN,
					compare_text_th: this.compareTH,
					compare_description_en: this.compareDescriptionEN,
					compare_description_th: this.compareDescriptionTH,
					html_contents: this.htmlContents?.map((content) => {
						return {
							id: content.id,
							title_en: content.titleEN,
							title_th: content.titleTH,
							content_en: content.contentEN,
							content_th: content.contentTH,
							priority: content.priority,
							is_active: content.isActive,
						};
					}),
					is_description_top_expanded: this.isExpand,

					// category slide banner
					is_lowest_price_visible: this.isLowestPriceVisibility,
					is_banner_visible: this.isBannerVisibility,
					// using computed as data when change
					lowest_price_category_ids: this.lowestPriceCategoryIdList.map((item) => item.id),

					product_family_ids: this.productFamiliesGroup.map((item) => item.id),

					// badge labels
					//* hide params for S7APP-1206
					// label_ids: this.badgeLabels.map((label) => label.id),

					// LOB/PLP
					line_product_list: {
						is_active: false,
						is_visible: false,
						title_en: '',
						title_th: '',
						description_en: '',
						description_th: '',
						media: {
							desktop_file_id: null,
							mobile_file_id: null,
						},
					},
					display_type: this.categoryDisplayType?.value || CATEGORY_DISPLAY_TYPE.SEARCH_LIST,
					search_keyword: this.searchKeyword,
					category_contents: this.localCategoryContentItems.map((item, index) => {
						return {
							title_en: item.titleEN,
							title_th: item.titleTH,
							sub_title_en: item.subTitleEN,
							sub_title_th: item.subTitleTH,
							display_lowest_price_type: item.displayLowestPriceType,
							is_lowest_price_visible: item.isLowestPriceVisible,
							short_description_en: item.shortDescriptionEN,
							short_description_th: item.shortDescriptionTH,
							description_en: item.descriptionEN,
							description_th: item.descriptionTH,
							type: item.type, // product or category
							type_id: item.typeId,
							destination_type: item.destinationType, // default or search
							search_keyword: item.searchKeyword,
							highlight: item.isHighlight,
							priority: index,
							is_active: item.isActive,
							start_at: item.startDate ? convertDateTimeToUTC(item.startDate, item.startTime ?? '00:00') : null,
							end_at: item.endDate ? convertDateTimeToUTC(item.endDate, item.endTime ?? '23:59') : null,
							use_default_button: Boolean(item.isActiveButton),
							custom_button_title_th: item.buttonNameTh,
							custom_button_title_en: item.buttonNameEn,
							custom_url: item.customLink,
							label_ids: item.badgeLabels.map((badge) => ({
								label_id: badge.id,
								is_label_visible: badge.isLabelVisible ? 1 : 0,
							})),
							media: {
								desktop_file_id: item.imageDesktop.id,
								mobile_file_id: item.imageMobile.id,
							},
							html_contents: item.isContentHTMLActive ?
								[{
									title_en: item.btnTitleEN,
									title_th: item.btnTitleTH,
									content_en: item.contentEN,
									content_th: item.contentTH,
									priority: 1,
									is_active: item.isContentHTMLActive,
								}] : []
							,
						};
					}),
				};
				await this.updateCategory({ categoryId, categoryData });

				// Show toast success
				if (this.edit.isSuccess) {
					// Reorder html cantents after success
					this.htmlContents = this.categoryInfo.htmlContents ?? [];

					this.showToast({
						content: this.$t('global.successMessage'),
						header: this.$t('global.successMessageTitle'),
						type: 'success',
					});

					// Set page title
					this.setPageTitle({
						title: this.name,
						isShow: true,
					});

					// Show toast if api return any errors
				} else {
					let content = this.$t('global.errorMessage');
					if (this.edit.fieldErrors.slug) {
						content = this.$t('page.categories.create.duplicateSlug');
					}

					this.showToast({
						content,
						header: this.$t('global.errorMessageTitleEdit'),
						type: 'danger',
					});
				}

				scrollToTop();
				await this.getCategory(categoryId);
				this.localCategoryContentItems = this.categoryInfo.categoryContents;
				this.banners = this.categoryInfo.banners;
			}
		},
		async handleRemove() {
			if (this.isDeletable && !this.isDeleting) {
				await this.deleteCategory(this.$route.params.id);

				this.showModalConfirmDelete = false;

				// when success re-direct to category list page
				if (this.delete.isSuccess) {
					this.$router.push({ name: 'CategoryLists' });

					// Show toast if api return any errors
				} else {
					this.showToast({
						content: this.$t('global.errorMessage'),
						header: this.$t('global.errorMessageTitleDelete'),
						type: 'danger',
					});
				}
			}
		},
		handleSelectedCategoryParentLevelOne() {
			this.clearError();

			// Should clear parent id level 2 value
			this.categoryParentLevelTwo = null;
		},
		handleUpdateStatus(value) {
			this.status = value;
			this.clearError();

			// When set status to false, should set visibility to false too
			if (value === false) {
				this.handleUpdateVisibility(false);
				this.handleUpdateTradeInTruemoveVisibility(false);
				this.handleUpdateNavbarVisibility(false);
			}
		},
		handleUpdateVisibility(value) {
			this.visibility = value;
			this.clearError();
		},
		handleUpdateNavbarVisibility(value) {
			this.navbarVisibility = value;
			this.clearError();
		},
		handleUpdateTradeInTruemoveVisibility(value) {
			this.tradeInTruemoveVisibility = value;
			this.clearError();
		},
		handleUpdateIsNew(value) {
			this.isNew = value;
			this.clearError();
		},
		handleUpdateIsVisibleViewAll(value) {
			this.isVisibleViewAll = value;
			this.clearError();
		},
		handleAutoFillMetaTitleAndSlug() {
			if (!this.metaTitle) {
				this.metaTitle = this.name;
			}

			if (!this.slug) {
				this.slug = slugify(this.name, {
					lower: true,
					strict: true,
				});
				this.$v.slug.$touch();
			}
		},
		handleAutoFillMetaTitleTH() {
			if (!this.metaTitleTH) {
				this.metaTitleTH = this.nameTH;
			}
		},
		handleOpenModalConfirmDelete() {
			this.inputConfirmText = null;
			this.showModalConfirmDelete = true;
		},
		handleUpdateBannerVisibility(value) {
			this.isBannerVisibility = value;
			this.clearError();
		},
		handleUpdateLowestPriceVisibility(value) {
			this.isLowestPriceVisibility = value;
			this.clearError();
		},
		exportProductRanking() {
			const categoryId = this.$route.params.id;
			exportAPI({
				key: 'product_ranking',
				category_id: categoryId,
			});
		},
		importProductRanking() {
			const categoryId = this.$route.params.id;
			const fileSelector = document.createElement('input');
			fileSelector.setAttribute('type', 'file');
			fileSelector.setAttribute(
				'accept',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
			);
			fileSelector.onchange = async () => {
				const file = fileSelector.files.item(0);
				const params = {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};

				try {
					await importProductRankingAPI(file, categoryId, params);
					this.showToast({
						content: 'Product ranking successfully uploaded. It could take up to 30 minutes for the result to take effect.',
						header: 'Success',
						type: 'success',
					});
				} catch (e) {
					this.showToast({
						content: 'Product ranking upload failed',
						header: 'Failure',
						type: 'danger',
					});
				}
			};
			fileSelector.click();
		},
		handleUpdateExpand(value) {
			this.isExpand = value;
			this.clearError();
		},
		handleProductFamiliesGroupListChange(list) {
			this.productFamiliesGroup = list;
		},
		openProductBadgeModal(type) {
			this.$refs['modal-badge-label'].open(type);
		},
		//* hide for S7APP-1206
		// handleSubmitBadgeLabel({ labels }) {
		// 	this.badgeLabels = labels;
		// },
		// handleDeleteBadgeLabel({ id }) {
		// 	this.badgeLabels = this.badgeLabels.filter((item) => item.id !== id);
		// },
		// Compare
		handleOnEditCompare(data, index) {
			this.$refs['modal-category-compare'].open(data, index);
		},
		moveCompareToTop(index) {
			this.htmlContents.unshift(this.htmlContents.splice(index, 1)[0]);
		},
		handleCompareOrder(sortedList = []) {
			this.htmlContents = sortedList;
			this.isDrag = true;
		},
		handleCategoryCompareChange(data, isChangePriority) {
			if (data.index !== null) {
				this.htmlContents[data.index] = data;
			} else {
				this.htmlContents = [
					...this.htmlContents,
					data,
				];
			}

			if (isChangePriority) {
				this.isDrag = false;
			}
			this.resetHtmlContents();
		},
		handleOnDeleteCompare(index) {
			this.htmlContents = this.htmlContents.filter((_, i) => i !== index);
			this.resetHtmlContents();
		},
		resetHtmlContents() {
			const htmlContentsTemp = this.htmlContents;
			this.htmlContents = [];

			this.$nextTick(() => {
				this.htmlContents = htmlContentsTemp;
			});
		},

		handleLowestPriceCategoryChange(data) {
			this.lowestPriceCategoryIds = data;
		},
	},
};
</script>

<style lang="scss" scoped>
hr {
	margin: rem(40) 0;
}

.level-label {
	margin-bottom: rem(4);
}

.lob-plp-label {
	flex-grow: 1;
}

.lob-plp-text {
	min-width: rem(85);
}

.toggle-label {
	width: rem(200);
	margin: 0 rem(24) 0 0;

	&.-is-expand-content {
		width: auto !important;
	}
}

.toggle-status,
.toggle-visibility {
	margin-right: rem(12);
}

.row-parent-category {
	// margin left, right come from .row
	margin-top: rem(40);
	margin-bottom: rem(-40);
}

.margin-bottom-0 {
	// .margin-bottom-0
	&-important {
		margin-bottom: rem(0) !important;
	}
}

.margin-bottom-32 {
	margin-bottom: rem(32);
}

.form-group {
	margin-bottom: rem(40);
}

.category-id {
	color: $color-black-70;
}

.compare-list {
	border: rem(2) solid $color-gray-100;
	background-color: $color-gray-100;
	border-radius: rem(4);
	list-style: none;
	min-height: rem(272);

	// .compare-list-dragable
	&-dragable {
		margin-bottom: 0;
		padding-left: 0;
	}
}

.category-slide-list {
	overflow-y: auto;
	min-height: rem(60);
	max-height: rem(468);
	padding: rem(20) rem(16) rem(8);
	border-radius: rem(4);
	border: solid 1px $color-gray-400;
	list-style: none;
	margin-bottom: 0;

	.list-item {
		border-radius: rem(4);
		background-color: $color-gray-300;
		color: $color-black-90;
		display: inline-block;
		padding: 0 rem(6);
		margin-right: rem(12);
		margin-bottom: rem(12);
	}
}

.content-banner {
	border: 1px solid $color-gray-800;
}
</style>
