<template>
	<CCol sm="4">
		<nav class="side-bar-nav text-color-black bg-white rounded-sm shadow-sm p-3">
			<div
				v-for="refKey in Object.keys(CATEGORY_REFS)"
				:key="refKey"
			>
				<div
					v-if="CATEGORY_REFS_VISIBLE[CATEGORY_REFS[refKey]]
						? CATEGORY_REFS_VISIBLE[CATEGORY_REFS[refKey]] === level : true"
					class="nav-item p-2"
					@click.prevent="handleClickNav(CATEGORY_REFS[refKey])"
				>
					{{ CATEGORY_REFS_TITLE[CATEGORY_REFS[refKey]] }}
				</div>
			</div>
		</nav>
	</CCol>
</template>

<script>
import { CATEGORY_REFS, CATEGORY_REFS_TITLE, CATEGORY_REFS_VISIBLE } from '../enums/categories';

export default {
	name: 'CategoryEditSidebar',
	props: {
		level: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			CATEGORY_REFS,
			CATEGORY_REFS_TITLE,
			CATEGORY_REFS_VISIBLE,
		};
	},
	methods: {
		handleClickNav(refNames) {
			this.$emit('onClickNav', refNames);
		},
	},
};
</script>


<style lang="scss" scoped>
.nav-item {
	cursor: pointer;
	font-size: rem(14);

	&:hover {
		background-color: $color-gray-100;
		border-radius: rem(4);
		color: $color-black-90;
		font-weight: 600;
	}
}

.side-bar-nav {
	position: sticky;
	top: rem(12);
	overflow-x: hidden;
	overflow-y: auto;
	max-width: rem(270);
	max-height: calc(100vh - 220px);
}
</style>
